import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import LaneTitle from "../../components/Kanban/LaneTitle";
import CardTitle from "../../components/Kanban/CardTitle";
import DeleteButton from "../../components/Kanban/DeleteButton";
import FooterButtons from "../../components/Kanban/FooterButtons";
import "./responsive.css";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [tags, setTags] = useState([]);
  const [reloadData, setReloadData] = useState(false);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      if(fetchedTags.length===0) {
        toast.warn('Nenhuma TAG cadastrada no CRM.');
      } else {
        setTags(fetchedTags);
      }
      
      // Fetch tickets after fetching tags
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const [file, setFile] = useState({
    lanes: []
  });

  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  const popularCards = (jsonString) => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);
    const lanes = tags.map((tag) => {
      const filteredTickets = tickets.filter((ticket) =>
        ticket.tags.some((ticketTag) => ticketTag.id === tag.id)
      );

      return {
        id: tag.id.toString(),
        title: tag.name,
        label: filteredTickets.length.toString(),
        cards: tickets.filter(ticket => ticket.tags.some(t => t.id === tag.id)).map(ticket => ({
          id: ticket.id.toString(),
          title: <CardTitle ticket={ticket} userProfile={user.profile} />,
          description: <FooterButtons ticket={ticket} />,
          draggable: true,
          href: "/tickets/" + ticket.uuid,
        })),
        style: { backgroundColor: tag.color, color: "white" },
      };
    });

    setFile({ lanes });
  };

  useEffect(() => {
    popularCards(jsonString);
  }, [tags, tickets, reloadData]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId, index) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}/${index}`);
      await fetchTickets(jsonString);
      popularCards(jsonString);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCardDelete = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${cardId}`);
      await fetchTickets(jsonString);
      popularCards(jsonString);
    } catch (err) {
      console.log(err);
    }
  };

  // Set up a cron job to call fetchTickets and popularCards every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      fetchTickets(jsonString); // Fetch tickets
      popularCards(jsonString); // Populate cards
    }, 10000); // 10,000 milliseconds = 10 seconds

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [jsonString]); // This ensures the effect runs whenever jsonString changes

  return (
    <div className={classes.root}>
      <Board
        data={file}
        onCardMoveAcrossLanes={handleCardMove}
        laneStyle={{ maxHeight: "85vh" }}
        onCardDelete={handleCardDelete}
        style={{
          backgroundColor: 'rgba(252, 252, 252, 0.03)',
          height: "calc(100vh - 48px)",
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          color: "#111b21", // Cor semelhante ao texto principal do WhatsApp
        }}
      />
    </div>
  );
};

export default Kanban;
