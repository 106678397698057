import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { BookOpen, Calendar } from "react-feather";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ScheduleModal from "../../ScheduleModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ContactNotesDialog from "../../ContactNotesDialog";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../../TicketMessagesDialog";

import Badge from '@mui/material/Badge'; // Certifique-se de importar o Badge
import { styled } from '@mui/material/styles'; // Import necessário para customizar estilos

// Estilizando o Badge para usar a cor verde
const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#25D366", // Cor verde do WhatsApp
        color: "white",            // Cor do texto no badge
        //fontWeight: "bold",
        transform: "translate(0, -4px)", // Move o badge um pouco mais para cima       
    },
}));

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center"
    }
  }));

export default function FooterButtons({ticket}) {
    const classes = useStyles();
    const color = "#4d4d4d"
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [contactNotesModal, setContactNotesModal] = useState(false)
    const history = useHistory();

    const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);

    const handleOpenScheduleModal = () => {
        setScheduleModalOpen(true);
    };

    const handleOpenContactNotesModal = () => {
        setContactNotesModal(true)
    }

    const handleCloseContactNotesModal = () => {
        setContactNotesModal(false)
    }

    const handleCloseScheduleModal = () => {
        setScheduleModalOpen(false);
    };

    const handleSelectTicket = () => {
        history.push(`/tickets/${ticket.uuid}`);
    };

    return (
        <>
            <TicketMessagesDialog
                open={openTicketMessageDialog}
                handleClose={() => setOpenTicketMessageDialog(false)}
                ticketId={ticket.id}
            />
            <ContactNotesDialog modalOpen={contactNotesModal} onClose={handleCloseContactNotesModal} ticket={ticket} />
            <div className={classes.container}>
                <Tooltip title="Anotações">
                <IconButton onClick={handleOpenContactNotesModal} size="small">
                    <BookOpen color={color} size={17} />
                </IconButton>
                </Tooltip>
                <Tooltip title="Criar agendamento">
                    <IconButton onClick={handleOpenScheduleModal} size="small">
                        <Calendar color={color} size={17} />
                    </IconButton>
                </Tooltip>
                    <Tooltip title="Ir para conversa">   
                    <IconButton onClick={handleSelectTicket} size="small">
                        <StyledBadge 
                            badgeContent={ticket.unreadMessages > 0 ? ticket.unreadMessages : null}
                            overlap="circular"
                        >
                            <WhatsAppIcon style={{ color: color }} fontSize="small" />
                        </StyledBadge>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Espiar Conversa">
                  <VisibilityIcon
                    onClick={() => setOpenTicketMessageDialog(true)}
                    fontSize="small"
                    style={{
                      color: color,
                      cursor: "pointer",
                      //marginLeft: 10,
                      verticalAlign: "middle"
                    }}
                  />
                </Tooltip>

                <ScheduleModal
                    open={scheduleModalOpen}
                    onClose={handleCloseScheduleModal}
                    aria-labelledby="form-dialog-title"
                    contactId={ticket.contact.id}
                />
            </div>
        </>
    )
}